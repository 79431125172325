import {Injectable, Optional} from '@angular/core';
import {Functions, httpsCallable} from '@angular/fire/functions';
import {IProdEvents} from '@shared/prod_events';
import retry from 'async-retry';
import axios, {AxiosInstance} from 'axios';
import {
   IBuildLoginTokenFnData,
   IBuildLoginTokenFnRes,
   ICheckSubscriptionData,
   ICheckSubscriptionRes,
   ICreateCheckoutFnData,
   ICreateCheckoutFnRes,
   IEmailCopyContactsFnData,
   IEmailCopyContactsFnRes,
   IEmptyFnData,
   IEmptyFnRes,
   IGetFeatureFlagsFnData,
   IGetFeatureFlagsFnRes,
   IHelloFnData,
   IHelloFnRes,
   ILogDbWriteEventData,
   ILogFnData,
   ILogFnEventData,
   ILogFnExceptionData,
   ILogFnMessageData,
   ILogFnProdEventData,
   ILogFnRes,
   ILogFnSegmentEventData,
   ILogFnUserEventData,
   IMigrationFnData,
   IMigrationFnRes,
   IStatsFnData,
   IStatsFnRes,
   ITestNewUserFnData,
   ITestNewUserFnRes,
   ITimeFnRes,
   ITokenMgmtFnData,
   ITokenMgmtFnRes,
   LogTypes,
   SHOULD_TRACK_DB_WRITES,
} from 'functions/src/srv_func_types';
import {appConfig} from '@app/app_config';
import {SentrySrv} from '@app/sentry.srv';

import {assert} from '@util/assert';
import {HttpStatus} from '@util/http_status';
import {appLog, breadcrumb} from './logging';

/**
 * Wrapper around calls to backend functions.
 *
 * Done this way to simplify the interface when used in the application and provide
 * typesafe interfaces to the functions.
 */
@Injectable({
   providedIn: 'root',
})
export class BackendFunctionsSrv {
   /** Instance to use to talk to the backend API. */
   protected _axiosInstance: AxiosInstance;

   /**
    * Construct service.
    *
    * Note: allow fn to be optional so we can use this in cases where firebase
    *       was not able to be built.
    * TODO: Split into REST vs no rest functions.
    */
   constructor(protected sentrySrv: SentrySrv, @Optional() public fns?: Functions) {
      this._axiosInstance = axios.create({
         baseURL: `${appConfig.apiDomain}/api`,
      });
   }

   get api(): AxiosInstance {
      return this._axiosInstance;
   }

   async logMsg(message: string, data?: Record<string, any>): Promise<ILogFnRes | null> {
      const log_ev: ILogFnMessageData = {
         message,
         data: data ?? {},
         type: LogTypes.MESSAGE,
      };
      return this.logFn(log_ev);
   }
   async logException(data: Omit<ILogFnExceptionData, 'type'>): Promise<ILogFnRes | null> {
      return this.logFn({...data, type: LogTypes.EXCEPTION});
   }

   logEvent(
      data: Partial<Omit<ILogFnEventData, 'type'>> &
         Pick<ILogFnEventData, 'eventName' | 'subEvent'>,
   ): void {
      breadcrumb(`logFn: event: ${data.eventName}`);
      const event_data: ILogFnEventData = {
         type: LogTypes.EVENT,
         eventName: data.eventName,
         subEvent: data.subEvent,
         message: data.message ?? '',
         data: data.data ?? {},
         sendToGa: data.sendToGa ?? false,
      };
      this.logFn(event_data).catch((e) => appLog.warn(e));
   }

   /** Log a user event.
    * This will add the records as needed to the user documents and sub-documents.
    * Meant to be used as a fire and forget type of thing.
    */
   logUserEvent(
      event: Omit<ILogFnUserEventData, 'type' | 'message' | 'data'>,
      data?: Record<string, any>,
   ): void {
      breadcrumb(`logUserEvent: ${event.eventName}`);

      const event_data: ILogFnUserEventData = {
         ...event,
         type: LogTypes.USER_EVENT,
         data: data ?? {},
         message: `user event: user:[${event.userId}] event:[${event.eventName}]`,
      };
      this.logFn(event_data).catch((e) => appLog.warn(e));
   }

   /** Log a product event event.
    * Meant to be used as a fire and forget type of thing.
    */
   logProdEvent(prodEvent: IProdEvents): void {
      breadcrumb(`logProdEvent: ${prodEvent.type}`);

      const event_data: ILogFnProdEventData = {
         type: LogTypes.PROD_EVENT,
         event: prodEvent,
         message: ``,
         data: {},
      };
      this.logFn(event_data).catch((e) => appLog.warn(e));
   }

   /**
    * Log a segment test event.
    * This will add the records as needed to the user documents and sub-documents.
    * Meant to be used as a fire and forget type of thing.
    */
   logSegmentTestEvent(
      event: Omit<ILogFnSegmentEventData, 'type' | 'message' | 'data'>,
      data?: Record<string, any>,
   ): void {
      breadcrumb(`logSegTestEvent: ${event.test} - ${event.variant} - ${event.eventName}`);

      const event_data: ILogFnSegmentEventData = {
         ...event,
         type: LogTypes.SEGMENT_EVENT,
         data: data ?? {},
         message: `segment event: test:[${event.test}] variant:[${event.variant}] event:[${event.eventName}]`,
      };
      this.logFn(event_data).catch((e) => appLog.warn(e));
   }

   logDbWrite(location: string): void {
      if (SHOULD_TRACK_DB_WRITES) {
         breadcrumb(`logDbWrite: ${location}`);

         const event_data: ILogDbWriteEventData = {
            type: LogTypes.DB_WRITE_EVENT,
            data: {},
            location,
            message: `DB write event:  location:[${location}]`,
         };
         this.logFn(event_data).catch((e) => appLog.warn(e));
      }
   }

   async logFn(data: ILogFnData): Promise<ILogFnRes | null> {
      appLog.log(`CLIENT_LOG FN: type: ${data.type}`, data);

      let resp;
      try {
         resp = await this.api.post('/call/log', data);
         if (resp.status !== HttpStatus.CREATED) {
            appLog.warn(`logFn returned unexpected stats: ${resp.status}`);
         }
      } catch (e: any) {
         if (axios.isAxiosError(e)) {
            if (
               e.response?.status === HttpStatus.FORBIDDEN ||
               e.response?.status === HttpStatus.NOT_FOUND
            ) {
               // pass.  I think this can happen when deploying new versions
            }
            // If internal server, then just log a message since server side failed
            else if ((e.response?.status ?? 0) >= 500) {
               breadcrumb('Failure details: ', {
                  data: {
                     headers: e.response?.headers,
                     data: e.response?.data,
                  },
               });
               this.sentrySrv.captureMessage('logFn: Internal server error');
            } else {
               breadcrumb('Failure details: ', {
                  data: {
                     headers: e.response?.headers,
                     data: e.response?.data,
                  },
               });
               this.sentrySrv.handleError(
                  e,
                  `logFn:${data.type} HTTP Error: ${
                     e.response?.statusText ?? 'UNKNOWN'
                  } - ignoring`,
               );
            }
         } else {
            this.sentrySrv.handleError(e, `logFn:${data.type} unknown error. ignoring`);
         }
         return null;
      }

      const resp_obj = resp.data as ILogFnRes;
      return resp_obj;
   }

   /**
    * Call REST API to build login token.
    */
   async buildLoginToken(data: IBuildLoginTokenFnData): Promise<IBuildLoginTokenFnRes> {
      try {
         const resp = await retry(
            async (_bail, count) => {
               breadcrumb(`buildLoginToken: attempt: ${count}`);
               const r = await this.api.post('/call/buildLoginToken', data, {
                  validateStatus: (s) => s >= 200 && s < 500,
               });
               return r;
            },
            {
               retries: 3,
               minTimeout: 250,
               maxTimeout: 2000,
            },
         );
         const resp_obj = resp.data as IBuildLoginTokenFnRes;
         return resp_obj;
      } catch (err: any) {
         const status_code = axios.isAxiosError(err) ? err.response?.status ?? 0 : null;
         const msg = `buildLoginToken: unexpected exception: ${err.message} - status: ${status_code}`;
         appLog.warn(msg);

         return {
            firebaseCustomToken: null,
            newUserWasCreated: false,
            errorMsg: msg,
            errorCode: status_code ?? 0,
         };
      }
   }

   /**
    * Call REST API to get feature flags
    */
   async getFeatureFlags(data: IGetFeatureFlagsFnData): Promise<IGetFeatureFlagsFnRes | undefined> {
      try {
         const resp = await this.api.post('/call/feature-flags', data);
         const resp_obj = resp.data as IGetFeatureFlagsFnRes;
         return resp_obj;
      } catch (err: any) {
         const status_code = axios.isAxiosError(err) ? err.response?.status ?? 0 : null;
         const msg = `getFeatureFlags: unexpected exception: ${err.message} - status: ${status_code}`;
         appLog.warn(msg);
         return undefined;
      }
   }

   /**
    * Create a checkout session for the given user.
    */
   async createCheckoutSesson(data: ICreateCheckoutFnData): Promise<ICreateCheckoutFnRes | null> {
      try {
         const resp = await this.api.post('/call/createCheckoutSession', data);
         const resp_obj = resp.data as ICreateCheckoutFnRes;
         return resp_obj;
      } catch (err: any) {
         appLog.warn('createCheckoutSesson: exception');
         if (axios.isAxiosError(err)) {
            if (err.response) {
               appLog.warn(`HTTP Error: [${err.response.status} - ${err.message}`);
            }
         }
         return null;
      }
   }

   /**
    * Return server time in epoch milliseconds.
    */
   async serverTime(): Promise<ITimeFnRes | null> {
      try {
         const resp = await this.api.get('/call/time');
         const resp_obj = resp.data as ITimeFnRes;
         return resp_obj;
      } catch (err: any) {
         appLog.warn('serverTime: exception calling - return null');
         return null;
      }
   }

   // ---- SERVER CALLABLE FUNCTIONS ---- //

   async hellowWorld(data: IHelloFnData): Promise<IHelloFnRes> {
      return this.callFn('helloWorld', data);
   }

   async checkSubscription(data: ICheckSubscriptionData): Promise<ICheckSubscriptionRes> {
      return this.callFn('checkSubscription', data);
   }

   /** Interface to the manage token function backend. */
   async manageToken(data: ITokenMgmtFnData): Promise<ITokenMgmtFnRes> {
      return this.callFn('manageToken', data);
   }

   /** Call the data migration helper. */
   async dataMigration(data: IMigrationFnData): Promise<IMigrationFnRes> {
      return this.callFn('dataMigrationCall', data);
   }

   async testNewUserCreated(data: ITestNewUserFnData): Promise<ITestNewUserFnRes> {
      return this.callFn('testNewUserCreated', data);
   }
   async emailCopyContacts(data: IEmailCopyContactsFnData): Promise<IEmailCopyContactsFnRes> {
      return this.callFn('emailCopyContacts', data);
   }

   async syncEmailQueueSettings(data: IEmptyFnData): Promise<IEmptyFnRes> {
      return this.callFn('syncEmailQueueSettings', data);
   }

   async getStatsDetails(data: IStatsFnData): Promise<IStatsFnRes> {
      return this.callFn('getStatsDetails', data);
   }

   async updateAnalyticsData(data: IEmptyFnData): Promise<IEmptyFnRes> {
      return this.callFn('updateAnalyticsData', data);
   }

   /**
    * On Failure throws function.https.HttpsError
    */
   async callFn<T = any>(name: string, data: any): Promise<T> {
      assert(this.fns != null);
      const fn = httpsCallable(this.fns, name);
      const resp = await fn(data);
      return resp.data as T;
   }
}
