import type {JsonObject} from 'type-fest';
import {IProdEvents} from './shared/prod_events';
import {SubscriptionTypes, UserEventName} from './shared/shared_db_types';

// ------ HELLO FUNCTION --------------- //
export interface IHelloFnData {
   message: string;
   throwError?: boolean;
}
export interface IHelloFnRes {
   message: string;
   code: number;
   app_name: string;
   config: any;
   isAdmin: boolean;
}

// ---- Subscription Check --- //
export interface ICheckSubscriptionData {
   userId: string;
   orgId: string | null;
   enterpriseId: string | null;
}
export interface ICheckSubscriptionRes {
   /** True iff the subscription is active and working. */
   active: boolean;

   /** True iff the user passed is the one that owns the subscription. */
   ownedByUser: boolean;

   /** Owning username */
   owner: string | null;

   /** The type of the subscription we have. */
   subType: SubscriptionTypes | null;

   /** The id of the subscription found */
   subId: string | null;

   /** The stripe customer id we are related to. */
   stripeCustomerId: string | null;

   /** The auth hash for the churn key handling. */
   churnKeyAuthHash: string | null;

   /** Subscription object details. */
   stripeSubscription: any;
}

// ------ TOKEN MANAGEMENT --------------- //
export interface ITokenMgmtFnData {
   action: 'create' | 'delete' | 'get';
   trello?: {
      restToken: string;
      apiKey: string;
   };
}

export interface ITokenMgmtFnRes {
   code: number;
   tokenDetails?: {
      created: string;
      amfToken: string;
   };
}

// ------ LOG FUNCTION --------------- //
export enum LogTypes {
   EXCEPTION = 'exception',
   MESSAGE = 'message',
   EVENT = 'event',

   /** And event that is tied to a specific user. */
   USER_EVENT = 'user_event',

   /** Event about a segment variant (A/B testing) */
   SEGMENT_EVENT = 'segment_event',

   /** Event to track writes. */
   DB_WRITE_EVENT = 'db_write_count',

   /** Wrap product events (mixpanel) */
   PROD_EVENT = 'prod_event',
}

export interface ILogFnDataBase {
   type: LogTypes;
   message: string;
   data: Record<string, any>;
}

/** Details needed for logging a message. */
export interface ILogFnMessageData extends ILogFnDataBase {
   type: LogTypes.MESSAGE;
}

/** Details needed for logging an exception. */
export interface ILogFnExceptionData extends ILogFnDataBase {
   type: LogTypes.EXCEPTION;
   data: {
      description: string;
      stack: string;
   };
}

/** Data needed for an event logging (to GA). */
export interface ILogFnEventData extends ILogFnDataBase {
   type: LogTypes.EVENT;
   eventName: string;

   /** If passed, log as a nested event. */
   subEvent?: string;

   /** If true, send on to GA backend. */
   sendToGa: boolean;
}

/**
 * Data needed for logging a user facing event.
 * Get's stored as a UserEventDoc remotely.
 */
export interface ILogFnUserEventData extends ILogFnDataBase {
   type: LogTypes.USER_EVENT;

   /** The name of the event to send. */
   eventName: UserEventName;

   /** The id of the user/member. */
   userId: string;

   /** Username is included in case we dynamically create user. */
   username: string | null;

   /** Id of the board associated. */
   boardId: string | null;
}

/**
 * Data needed for a product event.
 * Gets stored in mixpanel.
 */
export interface ILogFnProdEventData extends ILogFnDataBase {
   type: LogTypes.PROD_EVENT;

   /** Event to pass on */
   event: IProdEvents;
}

export interface ILogFnSegmentEventData extends ILogFnDataBase {
   type: LogTypes.SEGMENT_EVENT;

   /** The segment variant test. */
   test: string;

   /** variant name we are sampling */
   variant: string;

   /** The event to track for this test/variant.  Adds a count. */
   eventName: string;
}

/** Set true in releases where we track writes. */
export const SHOULD_TRACK_DB_WRITES = false;

/**
 * Track writing locations.
 */
export interface ILogDbWriteEventData extends ILogFnDataBase {
   type: LogTypes.DB_WRITE_EVENT;

   location: string;
}

export interface ILogFnRes {
   message: string;
}

export type ILogFnData =
   | ILogFnMessageData
   | ILogFnExceptionData
   | ILogFnEventData
   | ILogFnUserEventData
   | ILogFnProdEventData
   | ILogFnSegmentEventData
   | ILogDbWriteEventData;

// --------------------- LOGIN TOKEN --------------------------- //
export interface INewUserFnOpts {
   /** Is the user an editor.  If so we will start onboarding if new. */
   isEditor: boolean;

   /** If true, and is a new user, add them to product announcement list by default. */
   registerProdList: boolean;
}

export interface IBuildLoginTokenFnData {
   /** The trello API key for the app associated with the token. */
   trelloApiKey: string;

   /** A user's trello API token. */
   trelloToken: string;

   /** Options if we create a new user. */
   newUserOpts: INewUserFnOpts;

   /** Extra metadata to help with tracing issues. */
   metadata: {
      trelloUid: string | null;
      trelloUser: string | null;
   };
}

export interface IBuildLoginTokenFnRes {
   /** The custom token that can be used to login this user. */
   firebaseCustomToken: string | null;

   /** True iff a new user was created. */
   newUserWasCreated: boolean;

   /** If an error, set this value. */
   errorMsg: string | null;

   /** HTTP Status code for error. */
   errorCode: number | null;
}

// ------------------- SUBSCRIBE SESSION ------------------ //
export interface ICreateCheckoutFnData {
   /** The user the subscription is for. */
   userId: string;

   /** Id of the price they will subscribe to. */
   priceId: string;

   /** id of the subscription variant the user was presented. */
   subVariant: string;

   successUrl: string;
   cancelUrl: string;
}

export interface ICreateCheckoutFnRes {
   /** Error message if there is a failure. */
   error: any;

   /** The URL to redirect to subscribe */
   url: string;
}

// --- FEATURE FLAGS --- //
export interface IGetFeatureFlagsFnData {
   uid: string;
}

export interface IGetFeatureFlagsFnRes {
   flags: Record<string, boolean | string | number>;
   flagPayloads: Record<string, JsonObject>;
}

// --- TIME CALLS --- //
export interface ITimeFnRes {
   /** UTC epoch time in milliseconds. */
   time: number;
}

// ------- EMAIL FUNCTIONS ------------ //
export interface ITestNewUserFnData {
   message?: string;
}
export interface ITestNewUserFnRes {
   code: number;
}

export interface IEmailCopyContactsFnData {
   message?: string;
}
export interface IEmailCopyContactsFnRes {
   code: number;
}

// ------- ACTION FUNCTIONS ------------ //
export interface ISyncContactsFnData {
   startIdx?: number;
   message?: string;
}
export interface ISyncContactsFnRes {
   code: number;
}

// ------- DATA MIGRATIONS --------- //
/** Calling for migrating data backend. */
export interface IMigrationFnData {
   migration: 'v21_add_trials';

   // Extra data specific to the migration in hand
   extra?: any;
   message?: string;
}
/** Response for migration of data call. */
export interface IMigrationFnRes {
   code?: number;
   message?: string;
}

/** Empty functions. */
/** Common calling data for generic calls. */
export interface IEmptyFnData {
   message?: string;
}
/** Common response data for generic calls. */
export interface IEmptyFnRes {
   code?: number;
   message?: string;
}

// ----- STATS FUNCTIONS ----- //
export interface IStatsFnData {
   message?: string;
}
export interface IStatsFnRes {
   eventsUrl: string;
   boardsUrl: string;
   usersUrl: string;
   analyticsUrl: string;
}
