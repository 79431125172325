export const USER_DB = 'users';
export const USER_EVENTS_DB = 'user_events';
export const SEGMENT_TESTS_DB = 'segment_tests';

export const BOARD_METRICS_DB = 'board_metrics';

/**
 * List of ids used for powerup name in the system and databases.
 */
export enum PowerUpName {
   AmazingFields = 'amazing_fields',
}

/**
 * Data about a single board.
 *
 * Indexed inside IUsage doc as usage board.
 */
export interface IBoardMetricDocCommon<FIELD_VALUE_T, DAYJS_T> {
   /** Board id */
   id?: string;

   /** Name of the board. */
   name?: string;

   /** Id of the organization. */
   orgId?: string;

   /** Name of the organization. */
   orgName?: string;

   /** Datetime of the last access in server time. */
   lastAccess: FIELD_VALUE_T | DAYJS_T | any;

   /**
    * days that user accessed.
    * string: ISO 8601 dates
    */
   accessed: string[] | FIELD_VALUE_T;

   /**
    * Users that accessed.
    */
   users: string[] | FIELD_VALUE_T;

   /** If true, then we believe Amazing Fields is enabled on the board. */
   enabled: boolean;

   /** Feature metrics about the board */
   featureMetrics: IBoardFeatureMetrics;

   /** Size of the configuration. (compressed) */
   configSize: number;
}

// TODO: For each type
// - for each variable: count of used option

/** Metrics to track about board features. */
export interface IBoardFeatureMetrics {
   fieldCount: number;

   base: {
      show: {
         front: number;
         back: number;
         name: number;
         activity: number;
         hide: number;
      };

      cust: {
         // Number with custom field link enabled
         enabled: number;
      };

      calc: {
         // Number with calculations enabled
         enabled: number;
      };
   };

   text: {
      count: number;
   };

   // eslint-disable-next-line id-blacklist
   number: {
      count: number;

      /** How many of the count of number are progress bars. */
      progressBars: number;
   };

   date: {
      count: number;
   };

   bool: {
      count: number;
   };

   list: {
      count: number;
   };
}

/** Allowed subscription types. */
export enum SubscriptionTypes {
   V1_USER = 'user',
   V2_INDIVIDUAL = 'v2_individual',
   V2_ENTERPRISE = 'v2_enterprise',
}

/**
 * User account document.
 *
 * Indexed by user.id
 */
export interface IUserDocCommon<FIELD_VALUE_T, DAYJS_T> {
   /**
    * User id.
    *
    * Matches the trello user id value.
    */
   id?: string;

   /** The trello username for this user.
    * Null if we don't know it yet.
    */
   username: string | null;

   /** email address / user_id
    *
    * note: ensure that subaddressing is not in this one.
    */
   email: string | null;

   /** date time of user creation. */
   created: FIELD_VALUE_T | DAYJS_T | any;

   /** True if the user has edited a board in the past or
    * originally created account through settings panel authorization.
    */
   editor: boolean;

   /** Enterprise this user belongs to. */
   enterprise: string | null;

   /** The workspaces (organizations) this user belongs to. */
   workspaces: FIELD_VALUE_T | string[];

   /** Trello member details. */
   member: {
      id: string;
      fullName: string | null;
      username: string | null;
      initials: string | null;
      avatar: string | null;
      paidStatus: 'free' | string | null;
   };

   // --- Subscription Details --- //
   /** User has a trial until the given date time. */
   trialUntil: FIELD_VALUE_T | DAYJS_T | any;

   /** The type of subscription this user currently has. */
   subscriptionType: SubscriptionTypes | null;

   /** Id of the most current subscription that we are associated with. */
   subscriptionId: string | null;

   /**
    * True if the user is subscribed.
    * @deprecated Remove after version 11 (replaced by other sub details)
    */
   subscribed: boolean;

   /** Iff true, send receipts for payment */
   sendPaymentReceipts?: boolean;

   // -------- Email Details -------------- //
   /** True if the user is subscribed to product updates. */
   subscribedToProdList: boolean;

   /** Contact id in mailjet backend. */
   mailjetContactId: number | null;

   // ------------- Metrics ------------------------ //
   /** Datetime of the last access in server time. */
   lastAccess: FIELD_VALUE_T | DAYJS_T | any;

   /** days that user accessed.
    * string: ISO 8601 dates
    */
   accessed: string[] | FIELD_VALUE_T;

   /** Datetime of the last edit of a board setting. */
   lastEdit: FIELD_VALUE_T | DAYJS_T | any;

   /**
    * days that user edited a board setting.
    * string: ISO 8601 dates
    */
   edited: string[] | FIELD_VALUE_T;

   /** List of boards this user has enabled. */
   enabledBoards: string[] | FIELD_VALUE_T;

   /** List of boards this user has disabled. */
   disabledBoard: string[] | FIELD_VALUE_T;

   // --------- Marketing ------------ //
   // OLD: userSource: string | null;

   /** Details of the referral if any. */
   referral: ReferralDocTypes<FIELD_VALUE_T, DAYJS_T> | null;

   // ------- EVENT SUMMARY --------- //
   /** Map of event name to overall count of events of that name. */
   eventCounts: {
      [eventName: string]: number;
   };

   // ------- EXTRA DATA ------------ //
   data: Record<string, any> | null;
}

/** Names that are valid in the IUserEventDoc type */
export enum UserEventName {
   ENABLE = 'enable',
   DISABLE = 'disable',
   AUTH_START = 'auth_start',
   AUTH_SUCCESS = 'auth_success',
   AUTH_ABORT = 'auth_abort',
   ADD_FIELD = 'add_field',
   OPEN_TEMPLATE = 'open_template',
   OPEN_HELP = 'open_help',
   REMOVE_FIELD = 'remove_field',
   REMOVE_POWERUP_DATA = 'remove_powerup_data',
   EDIT_FIELD = 'edit_field',
   SUBSCRIPTION_START = 'sub_start',
   SUBSCRIPTION_REDIRECT = 'sub_stripe_redirect',
   SUBSCRIPTION_SUCCESS = 'sub_success',
   SUBSCRIPTION_ABORT = 'sub_abort',
   SUBSCRIPTION_FAIL = 'sub_fail',
}

/*
export enum UserSource {
   ORGANIC = 'organic',
   GOOGLE_ADS = 'google_ads',
}
*/

export interface IUserEventDocCommon<FIELD_VALUE_T, DAYJS_T> {
   /** Name of the user event. */
   name: UserEventName;

   /** id of the board this is associated with. */
   board: string | null;

   /** Datetime of the event */
   time: FIELD_VALUE_T | DAYJS_T | any;

   /** Block of data that can be added to the event. */
   data: Record<string, any>;
}

// ---- User Referral Source ---- //
// Track the details of where a user was originally referred from
// so we can use this for conversion tracking and acquisition
// optimization.

/** The type of referal for a given user. */
export enum UserReferralType {
   /** organic entry with no details.  ex. click on adding through listing page. */
   ORGANIC = 'organic',

   /** referral from a google ad sending to the listing page. */
   GOOGLE_ADS = 'google_ads',

   /** referral from a link used somewhere with a "page". */
   PAGE_LINK = 'page_link',
}

/**
 * Record for keeping track of the source of a user.
 */
export interface IUserReferralBase<FIELD_VALUE_T, DAYJS_T> {
   /** The type of tracking event this is. */
   type: UserReferralType;

   /** Timestamp the referal happened */
   timestamp: FIELD_VALUE_T | DAYJS_T | any;

   /** The powerup this is related to. */
   powerup: PowerUpName;
}

/** common data we store in the tracking event for type GadsClick. */
export interface IGadsReferralDoc<FIELD_VALUE_T, DAYJS_T>
   extends IUserReferralBase<FIELD_VALUE_T, DAYJS_T> {
   type: UserReferralType.GOOGLE_ADS;

   gads: {
      /** gclid associated with the redirect / click through */
      gclid: string | null;
      campaignid: string | null;
      adgroupid: string | null;
   };
}

/**
 * Store information about a page referral.
 * This is where we have a redirect setup as a link on a
 * give "page" that will send users to AMF.
 *
 * We track this based upon the simple name of the page to see
 * where we are getting users.
 */

export interface IPageLinkReferralDoc<FIELD_VALUE_T, DAYJS_T>
   extends IUserReferralBase<FIELD_VALUE_T, DAYJS_T> {
   type: UserReferralType.PAGE_LINK;

   pageLink: {
      /** The id of the page that sent us here */
      page: string | null;
   };
}

/**
 * Organic referal
 *
 * Catch all that really just means we don't know where they came from.
 */
export interface IOrganicReferralDoc<FIELD_VALUE_T, DAYJS_T>
   extends IUserReferralBase<FIELD_VALUE_T, DAYJS_T> {
   type: UserReferralType.ORGANIC;
}

export type ReferralDocTypes<FIELD_VALUE_T, DAYJS_T> =
   | IGadsReferralDoc<FIELD_VALUE_T, DAYJS_T>
   | IPageLinkReferralDoc<FIELD_VALUE_T, DAYJS_T>
   | IOrganicReferralDoc<FIELD_VALUE_T, DAYJS_T>;

/**
 * Structured to store counts of the events for segmented multi-variant tests.
 *
 * 'tests': {
 *    'test_name': {
 *       'variant1': {
 *          'event': count
 *       }
 *    }
 * }
 */
export interface ISegmentTestsDoc {
   tests: Record<
      // test_name
      string,
      Record<
         // variant name
         string,
         Record<
            // event name
            string,
            number
         >
      >
   >;
}

// --- Event Tracking -- //
/*
events/{event_name}

{
   '2021-12-01': number,
}


(future) sharded:

// events/{event_name}/counts/{date_str}/shard_counts/{NUM}
*/

export const EVENTS_DB = 'events';

/**
 * Maps from date of event to total count or to a map with disaggregated sub
 * events and a total count.
 */
export interface IEventDocument {
   [date_str: string]: number | {total: number; [sub: string]: number};
}

// ---- Progress Messages ---- //
export const PROGRESS_MESSAGES_DB = 'progress_messages';

export enum ProgressMessageIds {
   DATA_MIGRATION = 'data_migration',
}

/**
 * Used to track progress messages in various parts of the system.
 *
 * The doc id is the section of the system, and the record
 * always shows the latest update message.
 *
 * Used to allow the server side to log some information the client can
 * monitor.
 */
export interface IProgressMessageDocCommon<FIELD_VALUE_T, DAYJS_T> {
   /** The id of the progress message. */
   id?: string;

   /** The message to show. */
   message: string;

   /** The timestamp the message was set. */
   timestamp: FIELD_VALUE_T | DAYJS_T | any;
}

// ---- OLD ----- //
export const USER_METRICS_DB_DONT_USE = 'user_metrics';
/**
 * Usage Collection document.
 *
 * Indexed by user.id
 */
export interface IUserMetricCommon<FIELD_VALUE_T, DAYJS_T> {
   /** User id. */
   id?: string;

   /** If true, this has been migrated to new version. */
   migrated?: boolean;

   /** Datetime of the last access in server time. */
   lastAccess: FIELD_VALUE_T | DAYJS_T | any;

   /** days that user accessed.
    * string: ISO 8601 dates
    */
   accessed: string[] | FIELD_VALUE_T;

   /** Datetime of the last edit of a board setting. */
   lastEdit: FIELD_VALUE_T | DAYJS_T | any;

   /** days that user edited a board setting.
    * string: ISO 8601 dates
    */
   edited: string[] | FIELD_VALUE_T;

   /** True if the user is subscribed. */
   subscribed: boolean;

   /** List of boards this user has enabled. */
   enabledBoards: string[] | FIELD_VALUE_T;

   /** List of boards this user has disabled. */
   disabledBoard: string[] | FIELD_VALUE_T;
}
