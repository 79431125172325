import {SubscriptionTypes} from '@shared/shared_db_types';
import {
   AMF_LOCAL_POWERUP_ID,
   AMF_PROD_POWERUP_ID,
   AMF_STAGING_POWERUP_ID,
} from '@shared/shared_settings';
import {environment} from 'src/environments/environment';
import {AMAZING_FIELDS_APP_NAME} from '@trello/powerup_settings';

/** Common email to use for support. */
export const AMAZING_FIELDS_SUPPORT_EMAIL = 'support@amazingpowerups.com';

/** Number of milliseconds to delay saves
 *
 * In playwright we remove the delay since computers type faster than humans
 * and we need to save changes immediately to avoid race conditions.
 */
export const SAVE_DELAY_MS = environment.e2eTest ? 0 : 500;

/** How often to poll custom field data from the server. */
export const POLLING_INTERVAL_MS = 5000;

/** Wait this long until taking into account custom field changes. */
export const EDIT_STABILIZE_TIME_MS = 7 * 1000;

export const PRODUCTS_DB = 'products';
export const SUBSCRIPTIONS_DB = 'subscriptions';
export const PRICES_DB = 'prices';
export const CUSTOMERS_DB = 'customers';

export const SECTION_ICON = '/assets/section_icon_list-alt.svg';
export const EXPAND_WINDOW = '/assets/expand-solid-white.svg';
export const SHRINK_WINDOW = '/assets/arrows-down-to-line-solid-white.svg';
export const QUESTION_ICON = '/assets/circle-question-regular-white.svg';
export const AMZ_FIELDS_LOGO = '/assets/amazing_fields_icon.svg';
export const POWERED_BY_STRIPE_LOGO = '/assets/powered_by_stripe_purple.svg';

export const AMF_CONFIG_WALKTHRU_VIDEO_MP4 =
   'https://www.amazingpowerups.com/videos/amf_settings_getting_started.mp4';

export const SENTRY_DNS_TRS =
   'https://bd37251473584141b04845a4f3694f6e@o1116555.ingest.sentry.io/6150050';

export const AMF_BEACON_INIT_ID = 'ece4d137-05bb-42bd-971a-9b168c99a9fb';

export const enum StripeExt {
   CreatePortalLinkFunc = 'ext-firestore-stripe-subscriptions-createPortalLink',
}

// Amazing Fields Powerup - API Key
const PROD_TRELLO_API_KEY = 'ce65dd6182498e983a27815844c24397';

// AF Staging powerup
const STAGING_TRELLO_API_KEY = '1b8eddd2306be1e10e113c2cef4c55f1';

// API KEY for the AF Local Test powerup
const LOCAL_TRELLO_API_KEY = '9042b8186c0da5df3314479adddd650e';

// --- EXTENSION URLS --- //
export const AMF_FIREFOX_EXTENSION_URL =
   'https://addons.mozilla.org/en-US/firefox/addon/amazing-fields-for-trello/';
export const AMF_CHROME_EXTENSION_URL =
   'https://chrome.google.com/webstore/detail/amazing-fields-for-trello/ccadinhledjhcejldlkleoifmpiffoaf';
export const AMF_EDGE_EXTENSION_URL =
   'https://microsoftedge.microsoft.com/addons/detail/amazing-fields-for-trello/iaijgdfpnenoacgeinbkgnaiadjonnmn';

// ---- DOC SITE LINKS ---- //
export const AMF_DOCS_SITE_URL = 'https://docs.amazingpowerups.com/';

export const TRELLO_BOOK_URL = 'https://www.brittbuildsthings.com/book';

// doc links
export const AMF_DOCS_SUPPORTERS_PAGE_URL =
   'https://docs.amazingpowerups.com/article/20-amazing-fields-supporters';
export const AMF_DOCS_BACKUP_PAGE_URL =
   'https://docs.amazingpowerups.com/article/29-how-to-backup-trello-boards-with-amazing-fields';
export const AMF_DOCS_CUSTOM_FIELDS_PAGE_URL =
   'https://docs.amazingpowerups.com/article/13-custom-field-integration';

export const AMF_DOCS_PERMISSIONS_PAGE_URL =
   'https://docs.amazingpowerups.com/article/26-amazing-fields-trello-permissions';

// --- POWERUP LINKS --- //
export const AMF_POWERUP_LISTING_URL =
   'https://trello.com/power-ups/60e068efb294647187bbe4f5/amazing-fields';
export const AMF_SUPPORTER_PRICING_SURVEY_URL = 'https://forms.gle/fYgF6HEnb9xUQvdy9';

export interface IPriceDetails {
   /** Stripe price id */
   id: string;

   /** Amount in dollars */
   amount: number;
}

export interface IProductDetails {
   /** The subscription type for these product details. */
   subType: SubscriptionTypes;

   /** Stripe id of the Amazing Fields Product to purchase. */
   productId: string;

   /** Set of leveled prices for the product. */
   prices: {
      small: IPriceDetails;
      medium: IPriceDetails;
      large: IPriceDetails;
      xlarge: IPriceDetails;
   };
}

interface IConfig {
   v1UserProd: IProductDetails;
   v2IndProd: IProductDetails;
   v2EntProd: IProductDetails;

   /** At this number of users, you need an enterprise license. */
   enterpriseUserLimit: number;

   /** The number of fields the free plan is limited to. */
   freeFieldLimit: number;

   /**
    * The id for the currently running powerup.
    * Used to lookup data out of pluginData.
    */
   amzPowerUpId: string;

   amzPowerupName: string;

   /** Full domain name for the REST API. */
   apiDomain: string;

   /** Trello API key to use. */
   trelloApiKey: string;
}

// --- STAGING CONFIGURATION -- //
const stagingConfig: IConfig = {
   v1UserProd: {
      subType: SubscriptionTypes.V1_USER,
      productId: 'prod_JCSdgfAUtCczUs',
      prices: {
         //small: {id: 'price_1Jj1JyCG5KJVc4rswW9YQZXg', amount: 1},
         //medium: {id: 'price_1Jj1KXCG5KJVc4rsoNJHw36Q', amount: 3},
         small: {id: 'price_1Jj1KICG5KJVc4rs3e7qLWWI', amount: 2},
         medium: {id: 'price_1J8qtNCG5KJVc4rsEDat5xBV', amount: 4},
         large: {id: 'price_1KQBcsCG5KJVc4rsmsRRpnP4', amount: 6},
         xlarge: {id: 'price_1KQBd0CG5KJVc4rszSHyop7E', amount: 8},
      },
   },

   v2IndProd: {
      subType: SubscriptionTypes.V2_INDIVIDUAL,
      productId: 'prod_LjoxZ0lrfU4g4W',
      prices: {
         small: {id: 'price_1L6h2oCG5KJVc4rsEaSuqdzp', amount: 4},
         medium: {id: 'price_1L6h66CG5KJVc4rssWD2bNNW', amount: 6},
         large: {id: 'price_1L6h7gCG5KJVc4rslGaERwbz', amount: 8},
         xlarge: {id: 'price_1LkYPNCG5KJVc4rsZJVwi1C8', amount: 10},
      },
   },

   v2EntProd: {
      subType: SubscriptionTypes.V2_ENTERPRISE,
      productId: 'prod_LhSchoGSV37fPC',
      prices: {
         small: {id: 'price_1L6hAeCG5KJVc4rsEnEizzrf', amount: 25},
         medium: {id: 'price_1L6hBwCG5KJVc4rs3PnXPfVB', amount: 50},
         large: {id: 'price_1L6hErCG5KJVc4rsv1ujE7xP', amount: 100},
         xlarge: {id: 'price_1LkYR0CG5KJVc4rsoAxivN2T', amount: 150},
      },
   },

   freeFieldLimit: 10,
   enterpriseUserLimit: 5,

   // staging or local host
   amzPowerUpId: environment.local ? AMF_LOCAL_POWERUP_ID : AMF_STAGING_POWERUP_ID,
   amzPowerupName: 'AMF Testing',

   apiDomain: 'https://amazing-powerups-staging-api.web.app',

   trelloApiKey: environment.local ? LOCAL_TRELLO_API_KEY : STAGING_TRELLO_API_KEY,
};

// --- PRODUCTION CONFIGURATION -- //
const prodConfig: IConfig = {
   v1UserProd: {
      subType: SubscriptionTypes.V1_USER,
      productId: 'prod_JmnQe9RoUsaXMH',
      prices: {
         //small: {id: 'price_1JjQvICG5KJVc4rsw9LW8gdR', amount: 1},
         //medium: {id: 'price_1JjQvVCG5KJVc4rsnnzqPg5o', amount: 3},
         small: {id: 'price_1JjQvQCG5KJVc4rsH05ql9ZF', amount: 2},
         medium: {id: 'price_1J9EMrCG5KJVc4rsmXPl5OY7', amount: 4},
         large: {id: 'price_1KPZBsCG5KJVc4rsm9ZKuSIA', amount: 6},
         xlarge: {id: 'price_1KPZC2CG5KJVc4rsY2j7MXpY', amount: 8},
      },
   },

   v2IndProd: {
      subType: SubscriptionTypes.V2_INDIVIDUAL,
      productId: '',
      prices: {
         small: {id: 'price_1L6hccCG5KJVc4rsopILpILE', amount: 4},
         medium: {id: 'price_1L6hcfCG5KJVc4rsZd1sNAiT', amount: 6},
         large: {id: 'price_1L6hchCG5KJVc4rsVflxdRs2', amount: 8},
         xlarge: {id: 'price_1LkYWgCG5KJVc4rsxcyjYKDu', amount: 10},
      },
   },

   v2EntProd: {
      subType: SubscriptionTypes.V2_ENTERPRISE,
      productId: 'prod_LkbKOjGvoUJdOi',
      prices: {
         small: {id: 'price_1L6hcjCG5KJVc4rsjbthg8an', amount: 25},
         medium: {id: 'price_1L6hclCG5KJVc4rs0IQawOTE', amount: 50},
         large: {id: 'price_1L6hcnCG5KJVc4rsQz8qgS8a', amount: 100},
         xlarge: {id: 'price_1LkYWaCG5KJVc4rso0Dw0KqP', amount: 150},
      },
   },

   freeFieldLimit: 10,
   enterpriseUserLimit: 15,

   // Production
   amzPowerUpId: AMF_PROD_POWERUP_ID,
   amzPowerupName: AMAZING_FIELDS_APP_NAME,

   apiDomain: 'https://api.amazingpowerups.com',

   trelloApiKey: PROD_TRELLO_API_KEY,
};

// Make staging by default
// eslint-disable-next-line @typescript-eslint/naming-convention
export const appConfig: IConfig = environment.production ? prodConfig : stagingConfig;
